import { render, staticRenderFns } from "./TableCompanies.vue?vue&type=template&id=090a484a"
import script from "./TableCompanies.vue?vue&type=script&setup=true&lang=ts"
export * from "./TableCompanies.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TableCompanies.vue?vue&type=style&index=0&id=090a484a&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DataTable: require('/app/components/DataTable/index.vue').default,WidgetBlank: require('/app/components/Widget/WidgetBlank.vue').default,WidgetLayoutRowCellSingle: require('/app/components/Widget/WidgetLayoutRowCellSingle.vue').default})
